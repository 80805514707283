import { useState, useEffect } from 'react';
import Image from 'next/image'
import Link from 'next/link'
import Head from 'next/head'
import * as matter from 'gray-matter';
import md from 'markdown-it';
import SignUpForUpdatesForm from '../components/home/SignUpForUpdatesForm'
import Header from '../components/home/header';
import Footer from '../components/home/footer';
import Platforms from '../components/home/platforms';


function Home({ banner,
    eligibility,
    about_section,
    whats_in_left,
    whats_in_middle,
    whats_in_right,
    about_video,
    button_text }) {

    const [settings, setSettings] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [comingSoonMode, setComingSoonMode] = useState(null);
    const [show, setShow] = useState(false);

    useEffect(() => {
        setLoading(true)
        fetch('/api/settings')
            .then((res) => res.json())
            .then((data) => {
                // console.log('data', data);
                setComingSoonMode(data.SITE_COMING_SOON_MODE.value);
                setSettings(data)
                setLoading(false)
            })
    }, [])

    if (isLoading) return <p>Loading...</p>;
    if (!settings) return <p>No settings</p>;

    const video_url = "https://www.youtube.com/embed/" + about_video.video_id + "?rel=0&modestbranding=1&autohide=1&showinfo=0&controls=1";

    const video_url_homeschool = "https://www.youtube.com/embed/IApBim5kEPk" + "?rel=0&modestbranding=1&autohide=1&showinfo=0&controls=1";
    
    const handleClose = () => {
        setShow(false);
    };
    const handleShow = () => setShow(true);

    const closeModal = () => {
        setSideNav(false);
    };

    return (
        <>
            <Header settings={settings} />
            <Head>
                <title>SchoolBox | Your Education Vision, Our God-Given Mission.</title>
                <link href="https://use.fontawesome.com/releases/v5.8.2/css/all.css" rel="stylesheet" />
                <link href="https://use.typekit.net/irw4dav.css" rel="stylesheet" />
            </Head>
            <div id='home-page'>

                <section className='homeSection' id="banner">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col col-12 col-md-10 col-lg-8 text-center animated fadeInDown delay-1">
                                <h3>{banner.notice}</h3>
                                <h1><span>{banner.header}</span>{banner.subheader}</h1>
                                {/* <div className="bannerTxt">{banner.content}</div> */}
                                <div className="bannerTxt" dangerouslySetInnerHTML={{ __html: md().renderInline(banner.content) }} />
                                <div className="bannerLinks">
                                   
                                   <Platforms />
                                   {/* <Link href="/curriculum">
                                    <a  className="bannerLink">or Explore the Curriculum ›</a>
                                    </Link> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="homeSection" id="homeVideo">
                    <div className="container">
                    {/* <h2>{about_video.headline}</h2> */}
                        <div className="row">
                            <div className="col col-12 col-md-6" data-aos="fade-down">
                            <h2>About HF Homeschool</h2>
                                <div className='embed-container'>
                                <iframe src={video_url_homeschool} frameBorder='0' allowFullScreen></iframe>
                                {/* <img src="img/home/preview-video.png" /> */}
                                </div>
                            </div>
                            <div className="col col-12 col-md-6" data-aos="fade-down">
                            
                            <h2>About SchoolBox</h2>
                                <div className='embed-container'>
                                <iframe src={video_url} frameBorder='0' allowFullScreen></iframe>
                             
                                
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="homeSection" id="whats-inside">
                    <div className="container">
                        <h3 className="titleWhatsInside text-center" data-aos="fade-down">What’s Inside:</h3>
                        <div className="row insideRow">
                            <div className="col col-12 col-md-4" data-aos="fade-right">
                                <div className="insideBx">
                                    <div className="insideIcon"><img src="/img/home/icon_inside_1.svg" /></div>
                                    <div className="insideMeta">
                                        <h4 className="insideTitle">{whats_in_left.header}</h4>
                                        <div className="insideTxt"><div dangerouslySetInnerHTML={{ __html: md().render(whats_in_left.content) }} /></div>
                                    </div>
                                </div>
                            </div>
                            <div className="col col-12 col-md-4" data-aos="fade-right">
                                <div className="insideBx">
                                    <div className="insideIcon"><img src="img/home/icon_inside_2.svg" /></div>
                                    <div className="insideMeta">
                                        <h4 className="insideTitle">{whats_in_middle.header}</h4>
                                        <div className="insideTxt"><div dangerouslySetInnerHTML={{ __html: md().render(whats_in_middle.content) }} /></div>
                                    </div>
                                </div>
                            </div>
                            <div className="col col-12 col-md-4" data-aos="fade-right">
                                <div className="insideBx">
                                    <div className="insideIcon"><img src="img/home/icon_inside_3.svg" /></div>
                                    <div className="insideMeta">
                                        <h4 className="insideTitle">{whats_in_right.header}</h4>
                                        <div className="insideTxt"><div dangerouslySetInnerHTML={{ __html: md().render(whats_in_right.content) }} /></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="text-center" data-aos="fade-right"><Link href="/curriculum"><a className="button">Explore the Curriculum ›</a></Link></div> */}
                    </div>
                </section>
                <section className='homeSection' id="about">
                    <div className="container">
                        <div className="row justify-content-end">
                            <div className="col col-12 col-md-6" data-aos="fade-right">
                                <h3 className="sectionTitle text-blue">{about_section.headline}</h3>
                                <div dangerouslySetInnerHTML={{ __html: md().render(about_section.content) }} />
                            </div>
                        </div>
                        {/* <div className="text-center" data-aos="fade-right"><Link href="/curriculum"><a className="button">Explore the Curriculum ›</a></Link></div> */}
                    </div>
                </section>
                <section className='homeSection' id="eligibility">
                    <div className="container">
                        <div className="row">
                            <div className="col col-12 col-md-6">
                                <h3 className="sectionTitle text-blue" data-aos="fade-right">{eligibility.headline}</h3>
                                <div dangerouslySetInnerHTML={{ __html: md().render(eligibility.content) }} />
                                {/* <a className="button btn-outline-highlight" data-aos="fade-right" onClick={handleShow}>Sign up for updates ›</a> */}
                                {settings.SITE_COMING_SOON_MODE.value == 'false' &&
                                    <></>
                                    // <Link href="/register"><a className="button btn-outline-highlight" data-aos="fade-right">Create My Account ›</a></Link>
                                }
                            </div>
                        </div>
                    </div>
                </section>
                {settings.SITE_COMING_SOON_MODE.value == 'false' &&
                    <section className='homeSection' id="ready">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col col-12 col-md-11 col-lg-9" data-aos="fade-down">
                                    <h3 className="titleReady text-center text-highlight">Ready to Start Your Journey?</h3>
                                    <p className="font23">Why wait?! There’s no time like the present to be a beacon of hope and truth in your community. Let’s start a movement to revitalize Christian education in our nation!</p>
                                    {/* <a className="button" onClick={handleShow}>Sign up for updates ›</a> */}

                                    <Platforms />
                                </div>
                            </div>
                        </div>
                    </section>
                }
            </div>
            <SignUpForUpdatesForm
                show={show}
                handleClose={handleClose}
            />
            <Footer settings={settings} />
        </>
    )
}

export async function getStaticProps() {

    const home_main = matter.read('./public/cms/content/home/home_main.md');
    const who_can_sign_up = matter.read('./public/cms/content/home/who_can_sign_up.md');
    const about_schoolbox = matter.read('./public/cms/content/home/about_schoolbox.md');
    const whats_inside_left = matter.read('./public/cms/content/home/whats_inside_left.md');
    const whats_inside_middle = matter.read('./public/cms/content/home/whats_inside_middle.md');
    const whats_inside_right = matter.read('./public/cms/content/home/whats_inside_right.md');
    const opt_in_form = matter.read('./public/cms/content/home/opt_in_form.md');
    const about_video_template = matter.read('./public/cms/content/home/about_video.md');

    const banner = {
        "notice": home_main.data.notice,
        "header": home_main.data.header,
        "subheader": home_main.data.subheader,
        "content": home_main.content,
    }
    const eligibility = { "headline": who_can_sign_up.data.headline, "content": who_can_sign_up.content }
    const about_section = { "headline": about_schoolbox.data.headline, "content": about_schoolbox.content }
    const whats_in_left = { "header": whats_inside_left.data.header, "content": whats_inside_left.content }
    const whats_in_middle = { "header": whats_inside_middle.data.header, "content": whats_inside_middle.content }
    const whats_in_right = { "header": whats_inside_right.data.header, "content": whats_inside_right.content }
    const about_video = { "headline": about_video_template.data.headline, "video_id": about_video_template.data.video_id }
    const button_text = { "register.button": home_main.data.register_button }

    return {
        props: {
            banner,
            eligibility,
            about_section,
            whats_in_left,
            whats_in_middle,
            whats_in_right,
            about_video,
            button_text,
        },
    }
}

export default Home

