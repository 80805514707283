import { useState } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { Dropdown } from 'react-bootstrap';
import SignUpForUpdatesForm from './SignUpForUpdatesForm'
import Nav from './nav';

const Header = ({ settings }) => {

  // console.log('header settings', settings.SITE_COMING_SOON_MODE.value);

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const closeModal = () => {
    setSideNav(false);
  };
  
  return (
    <div>
      <div id='preloader'></div>
      <div id='headerBar'>
        <div className='herzogLogo'>
          <span>A Product of</span>
          <Link href={'https://herzogfoundation.com'} >
            <Image
              src='/img/home/logo_herzog_top.svg'
              width={170}
              height={20}
              alt='SchoolBox Top Logo'
            />
          </Link>
        </div>
        <div id='headerAccount'>
          { settings.SITE_COMING_SOON_MODE.value == 'false' &&
            <Link href='/signin'>
              <a>
                <i className='far fa-user-circle'></i> My Account
              </a>
            </Link>
          }
        </div>
      </div>

     
      {/* <header className='header text-center' style={{ textAlign: 'center' }}>
        <div id='headerLogo' className='animated fadeIn text-center' style={{ textAlign: 'center' }} >
          <Link href='/' passHref>
            <Image
              src='/img/logo/HF_Homeschool_Schoolbox_logo_web.png'
              width={612}
              height={64}
              alt='HomeSchool SchoolBox Top Logo'
            />
          </Link>

        
        </div>
        <nav className='animated fadeIn'>
        </nav>
        <div id='headerBtn' className='animated fadeInRight'>
        </div>
        <SignUpForUpdatesForm
          show={show}
          handleClose={handleClose}
        />
      </header> */}
    </div>
  );
};

export default Header;
