import React from 'react';
import Link from 'next/link';
import { Container, Row, Col } from 'react-bootstrap';

export default function Platforms() {
  return (
    <div className="row">

      <div className="col col-12 col-md-6">
        <div className="ctaBx">

        <div className="ctaImg"><img src="/img/logo/hf-home-school-only-2.png" /></div>

          <h3>HF Homeschool</h3>
          <div className="ctaDesc">Check out our guide to starting a Christian homeschool group or co-op!</div>
          <div className="ctaBtn">
            <Link href="/register#hs">
              <a className="button">Create an Account ›</a>
            </Link>
          </div>

        </div>
      </div>

      <div className="col col-12 col-md-6">
        <div className="ctaBx">

          <div className="ctaImg"><img src="/img/logo/SchoolBox-only.png" /></div>

          <h3>SchoolBox</h3>
          <div className="ctaDesc">Check out our guide to starting a Christian 5-day, hybrid, or microschool!</div>

          <div className="ctaBtn">
            <Link href="/register" className="ctaBtn">
              <a className="button">Create an Account ›</a>
            </Link>
          </div>
        </div>
      </div>

    </div>
  );
}
